import React, { Component, createRef } from 'react';
import { EnvironmentService } from '../../core/services/environmentService';
import { EmbedService, IEmbedService, Messages } from '@delta-defense/delta-utils';
import Loading from '../Loading/Loading';
import './SingleSignOn.scss';

interface Props {
	action: string
	environmentService: EnvironmentService
	embedService: IEmbedService
	locationSearch: string
	redirect_url: string
	uscca_access: string
	uscca_lead_fields: string
}

class SingleSignOn extends Component<Props> {
	static defaultProps;

	state = {
	  redirect_url: this.props.redirect_url
	};
	submitButton = createRef();

	componentDidMount() {
	  if (this.props.embedService.isEmbedded) {
	    this.props.embedService.messageParent(Messages.Redirect, {
	      action: this.props.action,
	      redirect_url: this.props.redirect_url
	    });
	  } else {
	    (async () => {
	      const params = new URLSearchParams(this.props.locationSearch);
	      if (params.has('verification')) {
	        try {
	          const verificationCodeResponse = await fetch('https://member-verification.uscca.workers.dev/get-verification-code', {
	            method: 'POST',
	            headers: {
	              'X-Auth-Token': this.props.uscca_access
	            }
	          });
	          const verificationCodeJson = await verificationCodeResponse.json();
	          const redirectUrlWithVerificationCode = new URL(this.props.redirect_url);
	          redirectUrlWithVerificationCode.searchParams.delete('verification');
	          redirectUrlWithVerificationCode.searchParams.set('verificationCode', verificationCodeJson.code);
	          this.setState({
	            redirect_url: redirectUrlWithVerificationCode.href
	          });
	        } catch (error) {
	          console.error('Unable to request verification code', error);
	        }
	      }

	      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
	      this.submitButton.current?.click();
	    })();
	  }
	}

	render() {
	  return (
	    <>
	      <div
	        style={{
	          height: '100vh',
	          display: 'flex',
	          alignItems: 'center',
	          justifyContent: 'center',
	          color: '#fff'
	        }}
	      >
	        <Loading size="2x" />
	      </div>
	      <form className="sso-form" method="POST" action={this.props.environmentService.ddComUrl + '/sso'}>
	        <input type="text" name="action" value={this.props.action} />
	        <input type="text" name="redirect_url" value={this.state.redirect_url} />
	        <input type="text" name="uscca_access" value={this.props.uscca_access} />
	        <input type="text" name="uscca_lead_fields" value={this.props.uscca_lead_fields} />
	        {/* @ts-expect-error TS(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message */}
	        <input ref={this.submitButton} type="submit" value="Submit" />
	      </form>
	    </>
	  );
	}
}

SingleSignOn.defaultProps = {
  environmentService: EnvironmentService.Instance(),
  embedService: EmbedService.instance(),
  locationSearch: window.location.search
};

export default SingleSignOn;
