import Environments from '@delta-defense/client-environments';

export const Domains = {
  Local: 'localhost',
  Dev: '.dev.usccadev.com',
  Staging: '.usccadev.com',
  CabelasShield: '.cabelasshield.com',
  Production: '.usconcealedcarry.com'
};

export const DDComUrls = {
  Local: 'http://0.0.0.0:4000',
  Dev: 'https://www.dev.deltadefensedev.com',
  Staging: 'https://www.deltadefensedev.com',
  Production: 'https://www.deltadefense.com'
};

export class EnvironmentService {
  static #instance: EnvironmentService | null = null;

  static Instance() {
    if (!EnvironmentService.#instance) {
      EnvironmentService.#instance = new EnvironmentService();
    }

    return EnvironmentService.#instance;
  }

  static Destroy() {
    EnvironmentService.#instance = null;
  }

  private constructor () { }

  private hostnameOverride: string | null = null;

  setHostnameForTesting(hostname: string | null) {
    this.hostnameOverride = hostname;
  }

  getHostname() {
    return this.hostnameOverride !== null ? this.hostnameOverride : window.location.hostname;
  }

  get isLocal() {
    return Environments.isLocal();
  }

  get isDev() {
    return Environments.isDev();
  }

  get isStaging() {
    return Environments.isStaging();
  }

  get isProduction() {
    return Environments.isProduction();
  }

  get isCabelasShield() {
    return this.getHostname().includes('cabelasshield.com');
  }

  get domain() {
    if (this.isLocal) {
      return Domains.Local;
    } else if (this.isDev) {
      return Domains.Dev;
    } else if (this.isStaging) {
      return Domains.Staging;
    } else if (this.isCabelasShield) {
      return Domains.CabelasShield;
    } else {
      return Domains.Production;
    }
  }

  get ddComUrl() {
    if (this.isLocal) {
      return DDComUrls.Local;
    } else if (this.isDev) {
      return DDComUrls.Dev;
    } else if (this.isStaging) {
      return DDComUrls.Staging;
    } else {
      return DDComUrls.Production;
    }
  }
}
