import React, { useEffect, useState } from 'react';

import { RedirectService } from '../../core/services/redirectService';
import Loading from '../Loading/Loading';
import SingleSignOn from '../SingleSignOn/SingleSignOn';
import { CookieKeys, decodeToken, EmbeddedCookieHandler } from '@delta-defense/delta-utils';
import { deleteAuthCookies } from '../../utilities/cookies';

export function LoadingSpinner() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
      }}
    >
      <Loading size="2x" />
    </div>
  );
}

function RedirectChecker({cookieService = EmbeddedCookieHandler.instance(), redirectService = RedirectService.Instance(), SingleSignOnComponent = SingleSignOn}) {
  const [authChecked, setAuthChecked] = useState(false);
  const [accessTokenExpired, setAccessTokenExpired] = useState(false);
  const [userId, setUserId] = useState('');
  const [redirectDestination, setRedirectDestination] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [leadToken, setLeadToken] = useState('');

  useEffect(() => {
    (async () => {
      const accessToken = await cookieService.getCookie(CookieKeys.AccessToken);

      setAccessTokenExpired(!accessToken);

      const userId = decodeToken(accessToken)?.userId || '';

      setUserId(userId);
      setAccessToken(accessToken);
      setLeadToken(await cookieService.getCookie(CookieKeys.LeadFields));

      if (accessTokenExpired || !userId) {
        await deleteAuthCookies();
      }
      setAuthChecked(true);
    })();
  }, []);

  useEffect(() => {
    if (authChecked && !accessTokenExpired && !!userId) {
      (async () => {
        setRedirectDestination(await redirectService.resolveRedirectUrl());
      })();
    }
  }, [authChecked, accessTokenExpired, userId]);

  if (authChecked && (accessTokenExpired || !userId)) {
    return (
      <>
        <LoadingSpinner />
        <SingleSignOn action="logout" redirect_url={redirectService.resolveRedirectUrlForLogout()} />
      </>
    );
  } else {
    return (
      <>
        <LoadingSpinner />

        {!!redirectDestination && (
          <SingleSignOn
            action="login"
            redirect_url={redirectDestination}
            uscca_access={accessToken}
            uscca_lead_fields={leadToken}
          />
        )}
      </>
    );
  }
}

export default RedirectChecker;
